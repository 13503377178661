export default {
	show: function(tid) {
		var tabLinks = Array.from(document.querySelectorAll('.nav-link'));
		tabLinks.forEach(function(linkItem) {
			linkItem.classList.remove('active');
		});
		var activeLink = document.querySelectorAll('#navlink' + tid)[0];
		activeLink.classList.add('active');

		var tabPanes = Array.from(document.querySelectorAll('.tab-pane'));
		tabPanes.forEach(function(tabItem) {
			tabItem.classList.remove('show');
			tabItem.classList.remove('active');
		});
		var activeTab = document.querySelectorAll('#tabpane' + tid)[0];
		activeTab.classList.add('show');
		activeTab.classList.add('active');
	}
}