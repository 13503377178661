<template>
	<main class="rsg">
		<vue-title title="Random String &amp; Password Generator"></vue-title>
		<div class="container">
			<div class="row mt-3">
				<div class="col-lg-8 pb-5">
					<h1>Random String &amp; Password Generator<small> Online</small></h1>
					<div class="alert alert-info my-5">Random String Generator creates random strings, which can be used for passwords or for whatever else you need. Use the fields below to fine-tune your results and then copy entire strings or parts of strings in order to create your random string.</div>
					<div id="rsg">
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group">
									<label for="numPasswords">Number of passwords:</label>
									<input type="number" min="1" max="20" class="form-control" :class="validateNumPasswords" id="numPasswords" v-model="numPasswords">
									<p class="small text-info">Enter a number between 1 and 20.</p>
								</div>
								<div class="form-group">
									<label for="passwordLength">Password length:</label>
									<input type="number" min="5" max="50" class="form-control" :class="validatePasswordLength" id="passwordLength" v-model="passwordLength">
									<p class="small text-info">Enter a number between 5 and 50.</p>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="mb-2">Include sets:</div>
								<div v-for="charSet in arrCharSets" :key="charSet" class="custom-control custom-checkbox">
									<input type="checkbox" class="custom-control-input" v-model="selectedSets" :id="charSet.value" :value="charSet.value">
									<label class="custom-control-label" :for="charSet.value">{{ charSet.text }}</label>
								</div>
							</div>
						</div>
						<p class="my-4"><button class="btn btn-primary" @click="generate">Generate</button></p>
						<div v-html="output"></div>
					</div>
				</div>
				<div class="col-lg-4 pb-5">
					<div id="sidebar-tabs">
						<ul class="nav nav-tabs">
							<li class="nav-item">
								<a class="nav-link active" id="navlink1" href="#" @click.prevent="change(1)">Random</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" id="navlink2" href="#" @click.prevent="change(2)">Password Tips</a>
							</li>
						</ul>
						<div class="tab-content mt-4 small">
							<div class="tab-pane fade show active" id="tabpane1">
								<p>The Oxford English Dictionary defines 'random' as: "Having no definite aim or purpose; not sent or guided in a particular direction; made, done, occurring, etc., without method or conscious choice; haphazard". This concept of randomness suggests a non-order or non-coherence in a sequence of symbols or steps, such that there is no intelligible pattern or combination.</p>
							</div>
							<div class="tab-pane fade" id="tabpane2">
								<p>The first step in protecting your online privacy is creating a safe password - i.e. one that a computer program or persistent individual won't easily be able to guess in a short period of time. This Google page has some useful <a href="https://accounts.google.com/PasswordHelp" target="_blank" rel="noopener noreferrer">tips for passwords</a>.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script>
import axios from 'axios';
import changeTab from '../_js/changeTab.js';

export default {
	name: "rsg",
	data: function() {
		return {
			numPasswords: 5,
			passwordLength: 20,
			selectedSets: ['small', 'capital', 'num', 'spec'],
			arrCharSets: [
				{value: 'small', text: 'Small letters: a..z'},
				{value: 'capital', text: 'Capital letters: A..Z'},
				{value: 'num', text: 'Digits: 0..9'},
				{value: 'spec', text: 'Special chars: !, @, #, $, %, ^, _'},
			],
			output: ''
		};
	},
	computed: {
		validateNumPasswords: function () {
			return (this.numPasswords < 1 || this.numPasswords > 20) ? 'is-invalid' : '';
		},
		validatePasswordLength: function () {
			return (this.passwordLength < 5 || this.passwordLength > 50) ? 'is-invalid' : '';
		}
	},
	methods: {
		generate: function () {
			if (this.numPasswords < 1) {
				this.numPasswords = 1;
			}
			if (this.numPasswords > 20) {
				this.numPasswords = 20;
			}
			if (this.passwordLength < 5) {
				this.passwordLength = 5;
			}
			if (this.passwordLength > 50) {
				this.passwordLength = 50;
			}
			if (this.selectedSets.length === 0) {
				this.selectedSets = ['small'];
			}
			var vm = this;
			axios.post('rsg-strings.php', {
					num_pass: vm.numPasswords,
					pass_len: vm.passwordLength,
					incset: vm.selectedSets
				})
				.then(function (response) {
					if (response.status === 200) {
						vm.output = response.data;
					} else {
						vm.output = 'Error fetching the strings.';
					}
				})
				.catch(function () {
					vm.output = 'Error fetching the strings.';
				});
		},
		change: function(id) {
			changeTab.show(id);
		}
	},
	mounted: function () {
		this.generate();
	}
}
</script>